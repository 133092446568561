<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1520_40964" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
      <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1520_40964)">
      <path d="M14 18.5L8 12.5L14 6.5L15.4 7.9L10.8 12.5L15.4 17.1L14 18.5Z" fill="white" />
    </g>
  </svg>
</template>
