import '@/assets/styles/base.scss'
import '@/assets/styles/utilities.scss'
import { generatePostalCodesDict } from './lib/postalCodeGeneration'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { querierMixin } from '@/lib/querierMixin'

const app = createApp(App)

app.use(router)

app.mount('#app')

window.getProvince = function (form) {
  const postalCode = form?.postalCode
  let province

  if (!window.allPostalCodesDict) generatePostalCodesDict()
  if (postalCode) province = window.allPostalCodesDict[postalCode]

  return { ...form, province: province }
}

window.generatePostalCodes = function (form) {
  if (!window.allPostalCodesDict) {
    generatePostalCodesDict()
  }
  return Object.keys(window.allPostalCodesDict).map(p => ({ id: p, value: p }))
}

window.generateShowrooms = async function (form) {
  // const query = `
  // {
  //   currentTargetSite {
  //     id
  //     name
  //     dealerships {
  //       id
  //       name
  //       descendants(type: showroom) {
  //         id
  //         name
  //         plainAddress
  //         address {
  //           street1
  //           street2
  //           city
  //           state
  //           postalCode
  //           country
  //         }
  //         location {
  //           latitude
  //           longitude
  //         }
  //       }
  //     }
  //   }
  // }
  // `
  // const { currentTargetSite } = await querierMixin.methods.queryApi(query)
  // if (currentTargetSite?.dealerships?.find(d => d.name === 'Barchetti')) {
  //   const showrooms = currentTargetSite?.dealerships?.find(d => d.name === 'Barchetti').descendants.filter(d => d.location?.latitude && d.location?.longitude && d.plainAddress)
  //   return showrooms
  // }
  // return []
  const targetSiteQuery = '{currentTargetSite { dealerships { id name }}}'
  const { currentTargetSite } = await querierMixin.methods.queryApi(targetSiteQuery)
  const dealership = currentTargetSite?.dealerships?.find(d => d.name === 'Barchetti')

  if (dealership?.id) {
    const query = `
    {
      showrooms(dealershipIds: [${dealership?.id ?? ''}]) {
        id
        name
        address
        location {latitude longitude}
      }
    }
    `

    const result = await querierMixin.methods.queryApi(query)
    const showrooms = result?.showrooms?.filter(sr => sr.location?.latitude && sr.location?.longitude && sr.address)

    return showrooms
  }

  return []
}

window.generateCountries = () => {
  const getCountries = () => {
    const lang = document.documentElement.lang || 'en'
    const A = 65
    const Z = 90
    const countryName = new Intl.DisplayNames([lang], { type: 'region' })
    const countries = {}

    for (let i = A; i <= Z; ++i) {
      for (let j = A; j <= Z; ++j) {
        let code = String.fromCharCode(i) + String.fromCharCode(j)
        let name = countryName.of(code)
        if (code !== name) {
          countries[code] = {
            name: name,
            code: code
          }
        }
      }
    }
    return countries
  }

  const countryDropdownOptions = Object.values(getCountries()).map(item => ({ id: item.code, value: item.name }))

  countryDropdownOptions.sort((a, b) => a.value.localeCompare(b.value))

  const seenNames = new Set()
  const seenIds = new Set()
  const uniqueCountries = []
  // Use to debug the duplicated if needed
  const duplicates = []

  countryDropdownOptions.forEach(item => {
    if (!seenNames.has(item.value) && !seenIds.has(item.id)) {
      seenNames.add(item.value)
      seenIds.add(item.id)
      uniqueCountries.push(item)
    } else {
      duplicates.push(item)
    }
  })

  return uniqueCountries.length ? uniqueCountries : [{ id: '', value: '' }]
}
