const postalCodeMapping = {
  RM: { prefix: '00', cityRanges: [['118', '199']], provinceRanges: [['010', '079']] },
  VT: { prefix: '01', cityRanges: [['100', '100']], provinceRanges: [['010', '039']] },
  RI: { prefix: '02', cityRanges: [['100', '100']], provinceRanges: [['010', '049']] },
  FR: { prefix: '03', cityRanges: [['100', '100']], provinceRanges: [['010', '049']] },
  LT: { prefix: '04', cityRanges: [['100', '100']], provinceRanges: [['010', '031']] },
  TR: { prefix: '05', cityRanges: [['100', '100']], provinceRanges: [['010', '039']] },
  PG: { prefix: '06', cityRanges: [['121', '135']], provinceRanges: [['010', '089']] },
  SS: { prefix: '07', cityRanges: [['100', '100']], provinceRanges: [['010', '052']] },
  NU: { prefix: '08', cityRanges: [['100', '100']], provinceRanges: [['010', '049']] },
  CA: { prefix: '09', cityRanges: [['121', '134']], provinceRanges: [['010', '069']] },
  // SU: { prefix: '09', cityRanges: [], provinceRanges: [['010', '069']] },
  OR: { prefix: '09', cityRanges: [['170', '170']], provinceRanges: [['070', '099']] },
  TO: { prefix: '10', cityRanges: [['121', '156']], provinceRanges: [['010', '099']] },
  AO: { prefix: '11', cityRanges: [['100', '100']], provinceRanges: [['010', '029']] },
  CN: { prefix: '12', cityRanges: [['100', '100']], provinceRanges: [['010', '089']] },
  VC: { prefix: '13', cityRanges: [['100', '100']], provinceRanges: [['010', '060']] },
  BI: { prefix: '13', cityRanges: [['900', '900']], provinceRanges: [['811', '899']] },
  AT: { prefix: '14', cityRanges: [['100', '100']], provinceRanges: [['010', '059']] },
  AL: { prefix: '15', cityRanges: [['121', '122']], provinceRanges: [['010', '079']] },
  GE: { prefix: '16', cityRanges: [['121', '167']], provinceRanges: [['010', '049']] },
  SV: { prefix: '17', cityRanges: [['100', '100']], provinceRanges: [['010', '058']] },
  IM: { prefix: '18', cityRanges: [['100', '100']], provinceRanges: [['010', '039']] },
  SP: { prefix: '19', cityRanges: [['121', '137']], provinceRanges: [['010', '038']] },
  MI: { prefix: '20', cityRanges: [['121', '162']], provinceRanges: [['001', '099']] },
  VA: { prefix: '21', cityRanges: [['100', '100']], provinceRanges: [['009', '062']] },
  CO: { prefix: '22', cityRanges: [['100', '100']], provinceRanges: [['010', '079']] },
  SO: { prefix: '23', cityRanges: [['100', '100']], provinceRanges: [['009', '041']] },
  LC: { prefix: '23', cityRanges: [['900', '900']], provinceRanges: [['801', '899']] },
  BG: { prefix: '24', cityRanges: [['121', '129']], provinceRanges: [['010', '069']] },
  BS: { prefix: '25', cityRanges: [['121', '136']], provinceRanges: [['010', '089']] },
  CR: { prefix: '26', cityRanges: [['100', '100']], provinceRanges: [['010', '049']] },
  LO: { prefix: '26', cityRanges: [['900', '900']], provinceRanges: [['811', '867']] },
  PV: { prefix: '27', cityRanges: [['100', '100']], provinceRanges: [['010', '061']] },
  NO: { prefix: '28', cityRanges: [['100', '100']], provinceRanges: [['010', '079']] },
  VB: { prefix: '28', cityRanges: [['921', '925']], provinceRanges: [['801', '899']] },
  PC: { prefix: '29', cityRanges: [['121', '122']], provinceRanges: [['010', '031']] },
  VE: { prefix: '30', cityRanges: [['121', '176']], provinceRanges: [['010', '039']] },
  TV: { prefix: '31', cityRanges: [['100', '100']], provinceRanges: [['010', '059']] },
  BL: { prefix: '32', cityRanges: [['100', '100']], provinceRanges: [['010', '047']] },
  UD: { prefix: '33', cityRanges: [['100', '100']], provinceRanges: [['010', '061']] },
  PN: { prefix: '33', cityRanges: [['170', '170']], provinceRanges: [['070', '099']] },
  TS: { prefix: '34', cityRanges: [['121', '151']], provinceRanges: [['010', '018']] },
  GO: { prefix: '34', cityRanges: [['170', '170']], provinceRanges: [['070', '079']] },
  PD: { prefix: '35', cityRanges: [['121', '143']], provinceRanges: [['010', '048']] },
  VI: { prefix: '36', cityRanges: [['100', '100']], provinceRanges: [['010', '078']] },
  VR: { prefix: '37', cityRanges: [['121', '142']], provinceRanges: [['010', '089']] },
  TN: { prefix: '38', cityRanges: [['121', '123']], provinceRanges: [['010', '099']] },
  BZ: { prefix: '39', cityRanges: [['100', '100']], provinceRanges: [['010', '058']] },
  BO: { prefix: '40', cityRanges: [['121', '141']], provinceRanges: [['010', '069']] },
  MO: { prefix: '41', cityRanges: [['121', '126']], provinceRanges: [['010', '059']] },
  RE: { prefix: '42', cityRanges: [['121', '124']], provinceRanges: [['010', '049']] },
  PR: { prefix: '43', cityRanges: [['121', '126']], provinceRanges: [['010', '059']] },
  FE: { prefix: '44', cityRanges: [['121', '124']], provinceRanges: [['010', '049']] },
  RO: { prefix: '45', cityRanges: [['100', '100']], provinceRanges: [['010', '039']] },
  MN: { prefix: '46', cityRanges: [['100', '100']], provinceRanges: [['010', '051']] },
  // FC: { prefix: '47', cityRanges: [['121', '122']], provinceRanges: [['010', '043']] },
  RN: { prefix: '47', cityRanges: [['921', '924']], provinceRanges: [['814', '869']] },
  RA: { prefix: '48', cityRanges: [['121', '125']], provinceRanges: [['010', '034']] },
  FI: { prefix: '50', cityRanges: [['121', '145']], provinceRanges: [['010', '068']] },
  PT: { prefix: '51', cityRanges: [['100', '100']], provinceRanges: [['010', '039']] },
  AR: { prefix: '52', cityRanges: [['100', '100']], provinceRanges: [['010', '048']] },
  SI: { prefix: '53', cityRanges: [['100', '100']], provinceRanges: [['010', '038']] },
  MS: { prefix: '54', cityRanges: [['100', '100']], provinceRanges: [['010', '038']] },
  LU: { prefix: '55', cityRanges: [['100', '100']], provinceRanges: [['010', '064']] },
  PI: { prefix: '56', cityRanges: [['121', '128']], provinceRanges: [['010', '048']] },
  LI: { prefix: '57', cityRanges: [['121', '128']], provinceRanges: [['014', '039']] },
  GR: { prefix: '58', cityRanges: [['100', '100']], provinceRanges: [['010', '055']] },
  PO: { prefix: '59', cityRanges: [['100', '100']], provinceRanges: [['011', '026']] },
  AN: { prefix: '60', cityRanges: [['121', '131']], provinceRanges: [['010', '048']] },
  PU: { prefix: '61', cityRanges: [['121', '122']], provinceRanges: [['010', '049']] },
  MC: { prefix: '62', cityRanges: [['100', '100']], provinceRanges: [['010', '039']] },
  AP: { prefix: '63', cityRanges: [['100', '100']], provinceRanges: [['010', '096']] },
  FM: { prefix: '63', cityRanges: [['900', '900']], provinceRanges: [['811', '858']] },
  TE: { prefix: '64', cityRanges: [['100', '100']], provinceRanges: [['010', '049']] },
  PE: { prefix: '65', cityRanges: [['121', '129']], provinceRanges: [['010', '029']] },
  CH: { prefix: '66', cityRanges: [['100', '100']], provinceRanges: [['010', '054']] },
  AQ: { prefix: '67', cityRanges: [['100', '100']], provinceRanges: [['010', '069']] },
  BA: { prefix: '70', cityRanges: [['121', '132']], provinceRanges: [['010', '057']] },
  FG: { prefix: '71', cityRanges: [['121', '122']], provinceRanges: [['010', '051']] },
  BR: { prefix: '72', cityRanges: [['100', '100']], provinceRanges: [['010', '029']] },
  LE: { prefix: '73', cityRanges: [['100', '100']], provinceRanges: [['010', '059']] },
  TA: { prefix: '74', cityRanges: [['121', '123']], provinceRanges: [['010', '028']] },
  MT: { prefix: '75', cityRanges: [['100', '100']], provinceRanges: [['010', '029']] },
  // // berletta: { prefix: '76', cityRanges: [['121', '121']], provinceRanges: [['011', '017'] }, // TODO ??]?? 
  // // andria: { prefix: '76', cityRanges: [['123', '123']], provinceRanges: [['011', '017'] }, // BT // TODO ?]???
  // // trani: { prefix: '76', cityRanges: [['125', '125']], provinceRanges: [['011', '017'] }, // BT // TODO ?]???
  BT: {
    prefix: '76',
    cityRanges: [
      ['121', '121'],
      ['123', '123'],
      ['125', '125']
    ],
    provinceRanges: [['011', '017']]
  },
  NA: { prefix: '80', cityRanges: [['121', '147']], provinceRanges: [['010', '081']] },
  CE: { prefix: '81', cityRanges: [['100', '100']], provinceRanges: [['010', '059']] },
  BN: { prefix: '82', cityRanges: [['100', '100']], provinceRanges: [['010', '038']] },
  AV: { prefix: '83', cityRanges: [['100', '100']], provinceRanges: [['010', '059']] },
  SA: { prefix: '84', cityRanges: [['121', '135']], provinceRanges: [['010', '099']] },
  PZ: { prefix: '85', cityRanges: [['100', '100']], provinceRanges: [['010', '059']] },
  CB: { prefix: '86', cityRanges: [['100', '100']], provinceRanges: [['010', '049']] },
  IS: { prefix: '86', cityRanges: [['170', '170']], provinceRanges: [['070', '097']] },
  CS: { prefix: '87', cityRanges: [['100', '100']], provinceRanges: [['010', '076']] },
  CZ: { prefix: '88', cityRanges: [['100', '100']], provinceRanges: [['020', '071']] },
  KR: { prefix: '88', cityRanges: [['900', '900']], provinceRanges: [['811', '842']] },
  RC: { prefix: '89', cityRanges: [['121', '135']], provinceRanges: [['010', '069']] },
  VV: { prefix: '89', cityRanges: [['900', '900']], provinceRanges: [['812', '868']] },
  PA: { prefix: '90', cityRanges: [['121', '151']], provinceRanges: [['010', '051']] },
  TP: { prefix: '91', cityRanges: [['100', '100']], provinceRanges: [['010', '031']] },
  AG: { prefix: '92', cityRanges: [['100', '100']], provinceRanges: [['010', '031']] },
  CL: { prefix: '93', cityRanges: [['100', '100']], provinceRanges: [['010', '019']] },
  EN: { prefix: '94', cityRanges: [['100', '100']], provinceRanges: [['010', '019']] },
  CT: { prefix: '95', cityRanges: [['121', '131']], provinceRanges: [['010', '049']] },
  SR: { prefix: '96', cityRanges: [['100', '100']], provinceRanges: [['010', '019']] },
  RG: { prefix: '97', cityRanges: [['100', '100']], provinceRanges: [['010', '019']] },
  ME: { prefix: '98', cityRanges: [['121', '168']], provinceRanges: [['020', '079']] },
  // // cesena: { prefix: '47', cityRanges: [[], provinceRanges: [['52]1', '522']] },
  FC: {
    prefix: '47',
    cityRanges: [['121', '122']],
    provinceRanges: [
      ['010', '043'],
      ['521', '522']
    ]
  },
  MB: { prefix: '20', cityRanges: [['900', '900']], provinceRanges: [['811', '886']] }
}

export const generatePostalCodesDict = () => {
  const postalCodeDict = {}

  for (const city in postalCodeMapping) {
    const { prefix, cityRanges, provinceRanges } = postalCodeMapping[city]

    const generateCodesInRange = ranges => {
      ranges.forEach(([start, end]) => {
        for (let code = parseInt(start); code <= parseInt(end); code++) {
          const fullCode = `${prefix}${code.toString().padStart(3, '0')}`
          postalCodeDict[fullCode] = city
        }
      })
    }

    generateCodesInRange(cityRanges)
    generateCodesInRange(provinceRanges)
  }

  // store postal codes in memory
  window.allPostalCodesDict = postalCodeDict
}
