<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <mask id="mask0_771_5149" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_771_5149)">
      <path d="M12 21.0004L10.55 19.7004C8.86667 18.1837 7.475 16.8754 6.375 15.7754C5.275 14.6754 4.4 13.6879 3.75 12.8129C3.1 11.9379 2.64583 11.1337 2.3875 10.4004C2.12917 9.66706 2 8.91706 2 8.15039C2 6.58372 2.525 5.27539 3.575 4.22539C4.625 3.17539 5.93333 2.65039 7.5 2.65039C8.36667 2.65039 9.19167 2.83372 9.975 3.20039C10.7583 3.56706 11.4333 4.08372 12 4.75039C12.5667 4.08372 13.2417 3.56706 14.025 3.20039C14.8083 2.83372 15.6333 2.65039 16.5 2.65039C18.0667 2.65039 19.375 3.17539 20.425 4.22539C21.475 5.27539 22 6.58372 22 8.15039C22 8.91706 21.8708 9.66706 21.6125 10.4004C21.3542 11.1337 20.9 11.9379 20.25 12.8129C19.6 13.6879 18.725 14.6754 17.625 15.7754C16.525 16.8754 15.1333 18.1837 13.45 19.7004L12 21.0004ZM12 18.3004C13.6 16.8671 14.9167 15.6379 15.95 14.6129C16.9833 13.5879 17.8 12.6962 18.4 11.9379C19 11.1796 19.4167 10.5046 19.65 9.91289C19.8833 9.32122 20 8.73372 20 8.15039C20 7.15039 19.6667 6.31706 19 5.65039C18.3333 4.98372 17.5 4.65039 16.5 4.65039C15.7167 4.65039 14.9917 4.87122 14.325 5.31289C13.6583 5.75456 13.2 6.31706 12.95 7.00039H11.05C10.8 6.31706 10.3417 5.75456 9.675 5.31289C9.00833 4.87122 8.28333 4.65039 7.5 4.65039C6.5 4.65039 5.66667 4.98372 5 5.65039C4.33333 6.31706 4 7.15039 4 8.15039C4 8.73372 4.11667 9.32122 4.35 9.91289C4.58333 10.5046 5 11.1796 5.6 11.9379C6.2 12.6962 7.01667 13.5879 8.05 14.6129C9.08333 15.6379 10.4 16.8671 12 18.3004Z" fill="white" />
    </g>
  </svg>
</template>
