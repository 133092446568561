<template>
  <svg :class="className" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="white" fill-opacity="0.1" />
    <rect x="10" y="13" width="20" height="2" rx="1" fill="white" />
    <rect x="7.5" y="19" width="25" height="2" rx="1" fill="white" />
    <rect x="10" y="25" width="20" height="2" rx="1" fill="white" />
  </svg>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: ''
    }
  }
}
</script>
