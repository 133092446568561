<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_771_5144" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_771_5144)">
      <path d="M8 20L6.6 18.575L9.175 16H2V14H9.175L6.6 11.425L8 10L13 15L8 20ZM16 14L11 9L16 4L17.4 5.425L14.825 8H22V10H14.825L17.4 12.575L16 14Z" fill="white" />
    </g>
  </svg>
</template>
