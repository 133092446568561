import { createRouter, createWebHistory } from 'vue-router'
import Profile from '@/views/Profile/Profile.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home/HomeView.vue')
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/views/AboutView.vue')
    },
    {
      path: '/trade-in',
      name: 'trade-in',
      component: () => import('@/views/TradeIn/TradeInView.vue')
    },
    {
      path: '/compare',
      name: 'compare',
      component: () => import('@/views/Comparison/ComparisonView.vue')
    },
    {
      path: '/promotions',
      name: 'promotions',
      component: () => import('@/views/Promotions/PromotionsView.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/ContactView.vue')
    },
    {
      path: '/contact/:id',
      name: 'Contact Details',
      component: () => import('@/views/ContactDetailsView.vue')
    },
    {
      path: '/me',
      name: 'me',
      component: Profile,
      children: [
        {
          path: 'account',
          name: 'Account',
          component: () => import('@/views/Profile/Account.vue'),
          props: {}
        },
        {
          path: 'favorites',
          name: 'Favorites',
          component: () => import('@/views/Profile/Favorites.vue')
        },
        {
          path: 'my-leads',
          name: 'MyLead',
          component: () => import('@/views/Profile/MyLead.vue')
        },
        {
          path: 'my-trade-in',
          name: 'MyTradeIn',
          component: () => import('@/views/Profile/MyTradeIn.vue')
        },
        {
          path: 'car',
          name: 'MyTestDrive',
          component: () => import('@/views/Profile/MyTestDrive.vue')
        },
        {
          path: 'order',
          name: 'MyOrder',
          component: () => import('@/views/Profile/MyOrder.vue')
        },
        {
          path: 'saved-searches',
          name: 'Saved Searches',
          component: () => import('@/views/Profile/SavedSearches.vue')
        }
      ]
    },
    {
      path: '/cookiePolicies',
      name: 'cookiePolicies',
      component: () => import('@/views/CookiePoliciesView.vue')
    },
    {
      path: '/policies',
      name: 'policies',
      component: () => import('../views/PoliciesView.vue')
    },
    {
      path: '/search/:brand?/:model?',
      name: 'search',
      component: () => import('../views/SearchView.vue')
    },
    {
      path: '/listing/:id',
      name: 'listing',
      component: () => import('../views/ListingView.vue')
    },
    {
      path: '/start/:id',
      name: 'Intro',
      component: () => import('../views/Intro/JourneyIntro.vue')
    },
    {
      path: '/order/:id',
      name: 'Order',
      component: () => import('../views/Order/Order.vue'),
      alias: ['/ordrer/:id']
    }
  ]
})

export default router
