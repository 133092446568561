<template>
  <div class="profile">
    <div class="profile-panel">
      <div class="avatar-box" v-if="currentUser">
        <div class="avatar">
          <div>{{ getAvatarName }}</div>
        </div>
        <div class="info">
          <p class="username">{{ currentUser?.name }}</p>
        </div>
      </div>
      <nav>
        <RouterLink data-test-id="my_account" :aria-label="t.myProfile" to="/me/account" v-if="currentUser">
          <UserIcon />
          <span>{{ t.myProfile }}</span>
        </RouterLink>
        <RouterLink data-test-id="my_favorites" :aria-label="t.favorites" to="/me/favorites">
          <HeartIcon />
          <span>{{ t.favorites }} {{ `(${favorites})` }}</span>
        </RouterLink>
        <RouterLink data-test-id="my_saved-searches" :aria-label="t.savedSearches" to="/me/saved-searches">
          <SearchIcon />
          <span>{{ t.savedSearches }} {{ `(${savedSearches})` }}</span>
        </RouterLink>
        <RouterLink data-test-id="my_trade-ins" :aria-label="t.myTradeIn" to="/me/my-trade-in">
          <TradeInIcon />
          <span>{{ t.myTradeIn }} {{ `(${totals.tradeIns})` }}</span>
        </RouterLink>
        <RouterLink data-test-id="my_searches" :aria-label="t.estimates" to="/me/estimate" class="hide">
          <FolderIcon />
          <span>{{ t.estimates }} (2)</span>
        </RouterLink>
        <RouterLink data-test-id="my_searches" :aria-label="t.reservedCars" to="/me/prepare-order" class="hide">
          <PrepareOrderIcon />
          <span>{{ t.reservedCars }}</span>
        </RouterLink>
        <RouterLink data-test-id="my_leads" :aria-label="t.myLeads" to="/me/my-leads">
          <PhoneIcon />
          <span>{{ t.myLeads }} ({{ totals.leads }})</span>
        </RouterLink>
        <RouterLink data-test-id="my_test_drives" :aria-label="t.myTestDrives" to="/me/car">
          <CarIcon />
          <span>{{ t.myTestDrives }} ({{ totals.testDrives }})</span>
        </RouterLink>
        <RouterLink data-test-id="my_orders" :aria-label="t.myOrders" to="/me/order">
          <OrderIcon />
          <span>{{ t.myOrders }} {{ `(${totals.orders})` }}</span>
        </RouterLink>
        <button class="logout" @click="logout" v-if="currentUser">
          <LogoutIcon />
          <span>Logout</span>
        </button>
      </nav>
    </div>
    <div class="profileSection">
      <RouterView />
    </div>
  </div>
</template>

<script>
import { RouterView, RouterLink } from 'vue-router'
import { langMixin } from '@/lang.js'
import UserIcon from '@/assets/icons/user.svg'
import HeartIcon from '@/assets/icons/heart.svg'
import { querierMixin } from '@/lib/querierMixin'
import SearchIcon from '@/assets/icons/search.svg'
import TradeInIcon from '@/assets/icons/trade-in.svg'
import FolderIcon from '@/assets/icons/folder.svg'
import PrepareOrderIcon from '@/assets/icons/prepare-order.svg'
import PhoneIcon from '@/assets/icons/call.svg'
import OrderIcon from '@/assets/icons/order.svg'
import CarIcon from '@/assets/icons/car.svg'
import LogoutIcon from '@/assets/icons/logout.svg'

export default {
  name: 'ProfilePage',
  components: {
    RouterView,
    RouterLink,
    UserIcon,
    HeartIcon,
    SearchIcon,
    TradeInIcon,
    FolderIcon,
    PrepareOrderIcon,
    OrderIcon,
    CarIcon,
    LogoutIcon,
    PhoneIcon
  },
  mixins: [langMixin('Profile'), querierMixin],
  inject: ['currentUser'],
  data() {
    return {
      savedSearches: JSON.parse(localStorage.getItem('searches'))?.length || 0,
      favorites: JSON.parse(localStorage.getItem('favorites'))?.length || 0,
      totals: {
        testDrives: 0,
        leads: 0,
        tradeIns: 0,
        orders: 0
      }
    }
  },
  computed: {
    getAvatarName() {
      return this.currentUser?.name
        ?.split(' ')
        ?.slice(0, 2)
        ?.map(item => item?.[0])
        ?.join('')
    }
  },
  watch: {
    currentUser(user) {
      if (user?.id) {
        this.getTotals(user.id)
      }
    }
  },
  async mounted() {
    window.addEventListener('saved-searches-changed', event => {
      this.savedSearches = event.detail.numSearch || 0
    })

    if (this.currentUser?.id) {
      this.getTotals(this.currentUser?.id)
    }
  },
  methods: {
    async getTotals(userId) {
      const totalsQuery = `{
        user(id: ${userId}) {
          testDrives {
            id
              state
          }
          leads {
            id
            state
            listing {
              id
            }
          }
          tradeIns {
            id
          }
          orders {
            id
            state 
          }
        }
    }`
      const result = await this.queryApi(totalsQuery)
      if (result.user) {
        const { testDrives, leads, tradeIns, orders } = result.user
        this.totals = {
          testDrives: testDrives?.filter(item => ['confirmed', 'pending', 'completed', 'cancelled', 'draft'].includes(item.state))?.length,
          leads: leads?.filter(item => item.listing && ['open', 'closed', 'contacted'].includes(item.state))?.length,
          tradeIns: tradeIns?.length || 0,
          orders: orders?.filter(order => order.state !== 'cancelled')?.length || 0
        }
      }
    },
    logout() {
      window.seezSdk.showLogout()
    }
  }
}
</script>

<style lang="scss">
.profile-panel {
  border-right: 1px solid #efefef;
  padding-right: 1em;
  padding: 0 110px;
  padding-top: 3rem;
  background-color: var(--light-gray);
  display: none;
  overflow-y: auto;

  @media screen and (min-width: 53.438rem) {
    display: flex;
    flex-direction: column;
  }
  .avatar-box {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    color: var(--white);
    gap: 1rem;

    .info {
      overflow: hidden;
    }

    .username {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      font-weight: 500;
    }

    .avatar {
      flex-shrink: 0;

      > div {
        text-transform: uppercase;
        background: #f2dada;
        border-radius: 50%;
        width: 58px;
        height: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--accent);
        font-weight: 700;
        font-size: 1.25rem;
      }
    }
  }

  > nav {
    z-index: 2;

    @media screen and (min-width: 53.438rem) {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: initial;
      border-radius: 0;
      z-index: 0;
      box-shadow: none;
      max-width: none;
      margin: 0;
    }

    > a {
      color: var(--white);
      position: relative;
      border-radius: 24px;
      display: flex;
      gap: 1em;
      align-items: center;
      margin: 0.3rem;
      font-size: 1.125rem;
      font-weight: 500;

      @media screen and (min-width: 53.438rem) {
        padding: 1em 1.5em;
        margin: 0;
      }

      &.router-link-exact-active {
        background: var(--white);
        color: var(--dark-gray);
        font-weight: 700;

        svg {
          color: var(--dark-red);
        }
      }
    }

    > .hide {
      display: none;
    }

    > button.logout {
      border: none;
      background: none;
      text-align: left;
      cursor: pointer;
      color: var(--white);
      display: flex;
      gap: 1em;
      align-items: center;
      font-family: var(--font-family), sans-serif;
      font-size: 1.125rem;
      font-weight: 500;
      padding: 1rem;

      svg {
        margin-left: 7px;
      }
    }

    > a,
    button {
      padding: 1rem;
    }
  }

  @media screen and (max-width: 53.438rem) {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px 0 20px !important;
    height: fit-content;
    .avatar-box {
      margin: 0 0 1rem 1.5rem;
      .avatar,
      .description {
        display: none;
      }
    }
    > nav {
      display: flex;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      > a {
        flex-shrink: 0;
        padding: 0.625rem 1rem;
        background-color: var(--neutral-90);
      }

      > button.logout {
        display: none;
      }
    }
  }
}

.profile {
  position: relative;
  display: grid;
  grid-template-columns: 500px auto;
  text-align: left;
  height: calc(100svh - var(--header-height));
  overflow: hidden;

  > .profileSection {
    overflow: auto;
  }

  @media screen and (max-width: 75rem) {
    grid-template-columns: 400px auto;

    .profile-panel {
      padding: 50px 50px 0;
    }
  }

  @media screen and (max-width: 53.438rem) {
    grid-template-columns: 1fr;

    > .profileSection {
      padding-bottom: 0;
    }
  }
}
</style>
