<template>
  <header>
    <nav>
      <div class="max-w-screen-lg flex flex-wrap items-center justify-between mx-auto">
        <a href="/" class="flex items-center" data-test-id="logo">
          <span><IconLogo /></span>
        </a>

        <div class="flex items-center order-2-lg">
          <div class="flex items-center nav-profile">
            <!-- <RouterLink to="/me/trade-in">
              <span><IconCompare /></span>
            </RouterLink> -->

            <a href="/me/favorites">
              <span><IconHeart /></span>
            </a>

            <div ref="userMenu" class="relative hidden flex-lg items-center text-white cursor-pointer" @click="toggleUserDropdown">
              <span><IconUser /></span>
              {{ t.profile }}
              <IconArrowDown />

              <ul v-if="showUserDropdown" class="user-dropdown">
                <li v-if="!currentUser" key="login" @click="$root.login" class="login">
                  <span class="block text-white"><IconLogout /></span>
                  <span class="block text-white">{{ t.login }}</span>
                </li>
                <template :key="route.name" v-for="route in profileRoutes">
                  <li v-if="!route.hidden">
                    <a :href="route.path" class="block text-white">
                      <component :is="route.icon" />
                      {{ route.name }}</a
                    >
                  </li>
                </template>
                <li v-if="currentUser" key="logout" @click="logout" class="logout">
                  <span class="block text-white"><IconLogout /></span>
                  <span class="block text-white">{{ t.logout }}</span>
                </li>
              </ul>
            </div>
          </div>
          <button @click="onToggle" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center rounded-lg hidden-lg bg-transparent nav-button">
            <span class="sr-only">Open main menu</span>
            <IconMenu v-if="!showMenu" class-name="flex-shrink-0" />
            <IconMenuClose v-else class-name="flex-shrink-0" />
          </button>
        </div>

        <div class="nav-desktop">
          <ul class="flex flex-col flex-row-lg my-2">
            <li :key="route.name" v-for="route in routes">
              <a :href="route.path" :class="['block py-2 pl-4 pr-5 text-white', { active: isActive(route.path) }]">{{ route.name }}</a>
            </li>
          </ul>
        </div>
        <div :class="{ navMobile: true, showed: showHomeMenu, hideWithoutTransition: !showMenu }">
          <ul>
            <li :key="route.name" v-for="route in routes">
              <a :href="route.path">{{ route.name }}</a>
            </li>
            <li v-if="!currentUser" key="login" @click="$root.login">
              <span>{{ t.login }}</span>
              <span><IconChevronRight /></span>
            </li>
            <li v-if="currentUser" key="profile" @click="handleShowProfileMenu">
              <span>{{ t.profile }}</span>
              <span><IconChevronRight /></span>
            </li>
          </ul>
        </div>
        <div :class="{ profileMenu: true, showed: showProfileMenu, hideWithoutTransition: !showMenu }">
          <ul>
            <li key="back" @click="handleShowHomeMenu">
              <span><IconChevronLeft /></span>
              <span>{{ t.profile }}</span>
            </li>
            <li :key="route.name" v-for="route in profileRoutes">
              <a :href="route.path">{{ route.name }}</a>
            </li>
            <li key="logout" @click="logout">
              <span><IconLogout /></span>
              <span>{{ t.logout }}</span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import IconLogo from '@/components/icons/IconLogo.vue'
import IconCompare from '@/components/icons/IconCompare.vue'
import IconHeart from '@/components/icons/IconHeart.vue'
import IconUser from '@/components/icons/IconUser.vue'
import IconArrowDown from '@/components/icons/IconArrowDown.vue'
import { RouterLink } from 'vue-router'
import IconMenu from '@/components/icons/IconMenu.vue'
import IconMenuClose from '@/components/icons/IconMenuClose.vue'
import IconChevronRight from '@/components/icons/IconChevronRight.vue'
import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'
import IconLogout from '@/assets/icons/logout.svg'
import UserIcon from '@/assets/icons/user.svg'
import OrderIcon from '@/assets/icons/order.svg'
import SearchIcon from '@/assets/icons/search.svg'
import { langMixin } from '@/lang.js'

export default {
  name: 'HeaderComponent',
  components: { IconMenuClose, IconMenu, IconArrowDown, IconUser, IconHeart, IconCompare, IconLogo, RouterLink, IconChevronRight, IconChevronLeft, IconLogout },
  mixins: [langMixin('Common')],
  inject: ['currentUser'],
  data() {
    return {
      showUserDropdown: false,
      showHomeMenu: false,
      showProfileMenu: false
    }
  },
  computed: {
    routes() {
      return [
        {
          name: this.t.search,
          path: '/search'
        },
        {
          name: this.t.sell,
          path: '/trade-in'
        },
        {
          name: this.t.promotions,
          path: '/promotions'
        },
        {
          name: this.t.locationsAndContact,
          path: '/contact'
        }
      ]
    },
    profileRoutes() {
      return [
        {
          name: this.t.myAccount,
          path: '/me/account',
          icon: UserIcon,
          hidden: !this.currentUser
        },
        {
          name: this.t.orders,
          path: '/me/order',
          icon: OrderIcon
        },
        {
          name: this.t.savedSearches,
          path: '/me/saved-searches',
          icon: SearchIcon
        }
      ]
    },
    showMenu() {
      return this.showHomeMenu || this.showProfileMenu
    }
  },
  watch: {
    $route() {
      if (this.showMenu) {
        this.showHomeMenu = false
        this.showProfileMenu = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutsideUserDropdown)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutsideUserDropdown)
  },
  methods: {
    isActive(route) {
      return this.$route.path.includes(route)
    },
    clickOutsideUserDropdown(event) {
      if (!this.$refs.userMenu.contains(event.target)) {
        this.showUserDropdown = false
      }
    },
    onToggle() {
      if (this.showMenu) {
        this.showHomeMenu = false
        this.showProfileMenu = false
      } else {
        this.showHomeMenu = true
      }
    },
    toggleUserDropdown() {
      this.showUserDropdown = !this.showUserDropdown
    },
    handleShowProfileMenu() {
      this.showProfileMenu = true
      this.showHomeMenu = false
    },
    handleShowHomeMenu() {
      this.showProfileMenu = false
      this.showHomeMenu = true
    },
    logout() {
      window.seezSdk.showLogout()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/mixins';
header {
  height: var(--header-height);
  z-index: 10;
}

ul {
  list-style: none;
}

.nav-button {
  outline: none;
  border: none;
}

.nav-profile {
  gap: 0.75em;
  margin-right: 0.75em;

  @include minScreen(lg) {
    margin-right: 0;
  }
}

.nav-desktop {
  display: none;

  @include minScreen(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul li a.active {
    text-decoration: underline 2px var(--background);
    text-underline-offset: 0.5rem;
  }
}

.navMobile,
.profileMenu {
  overflow-y: auto;
  transition: transform 0.4s;

  &.hideWithoutTransition {
    transition: unset;
  }

  margin: 0;
  background: var(--dark-gray);
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 40px;

    li {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 1.125rem;

      a,
      span {
        cursor: pointer;
        color: var(--background);
      }

      span {
        display: flex;
        align-items: center;
      }

      a {
        padding: 0;
      }
    }
  }

  @include minScreen(lg) {
    display: none;
  }
}

.navMobile {
  transform: translateX(-100%);

  &.showed {
    transform: translateX(0);
  }
}

.profileMenu {
  transform: translateX(100%);

  &.showed {
    transform: translateX(0);
  }

  ul {
    li {
      padding-left: 20px;

      &:first-child {
        font-weight: 700;
      }

      &:first-child,
      &:last-child {
        padding-left: 0;
      }
    }
  }
}

.user-dropdown {
  z-index: 3;
  color: var(--background);
  position: absolute;
  right: 0;
  top: 4rem;
  background-color: var(--dark-gray);
  min-width: calc(100% - 1em);
  margin: 0;
  padding: 0.5rem 0.8rem;
  list-style-type: none;
  border-radius: 0.25em;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  li {
    border-radius: 0.25rem;

    a,
    &.login,
    &.logout {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
    }

    &:hover {
      background-color: var(--background);

      a,
      span,
      svg {
        color: var(--font-color);
      }
    }

    &.login,
    &.logout {
      > span:first-child {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
