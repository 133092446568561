<template>
  <div class="success-modal">
    <seez-sdk-modal v-if="isOpen" @close="$emit('close')">
      <div class="success-modal__content">
        <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="105" height="105" rx="52.5" fill="#CC0605" />
          <mask id="mask0_3064_40915" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="26" y="26" width="53" height="53">
            <rect x="26" y="26" width="53" height="53" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_3064_40915)">
            <path d="M47.0895 65.7505L34.502 53.163L37.6488 50.0161L47.0895 59.4568L67.3509 39.1953L70.4978 42.3422L47.0895 65.7505Z" fill="white" />
          </g>
        </svg>
        <div class="success-modal__title">{{ item.title }}</div>
        <div class="success-modal__subtitle">{{ item.subtitle }}</div>
        <button v-if="item.buttonText" class="primaryButton my-trade-in-btn" @click="actionClick">{{ item.buttonText }}</button>
      </div>
    </seez-sdk-modal>
  </div>
</template>

<script>
export default {
  name: 'SuccessModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['close'],
  methods: {
    actionClick() {
      this.item.buttonClick()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.success-modal {
  &__content {
    @media screen and (min-width: 64rem) {
      width: 50rem;
      padding: 3rem;
    }

    svg {
      margin-bottom: 4.25rem;
    }

    > .my-trade-in-btn {
      border-radius: 1.75rem;
      padding: 0.75rem 3rem;
      white-space: normal;
    }
  }

  &__title {
    font-size: 1.5625rem;
    font-weight: 700;
    text-align: center;
  }

  &__subtitle {
    margin-top: 1.25rem;
    font-size: 1.5625rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}
</style>
