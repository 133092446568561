<template>
  <svg :class="className" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="white" fill-opacity="0.1" />
    <rect x="12" y="26.1421" width="20" height="2" rx="1" transform="rotate(-45 12 26.1421)" fill="white" />
    <rect x="13.4141" y="12.1421" width="20" height="2" rx="1" transform="rotate(45 13.4141 12.1421)" fill="white" />
  </svg>
</template>

<script>
export default {
  props: {
    className: { type: String, default: '' }
  }
}
</script>
