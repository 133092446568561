<template>
  <svg width="158" height="42" viewBox="0 0 158 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.35899 1H6.60899V0.75H6.35899V1ZM1 1V0.75H0.75V1H1ZM6.35899 8.30753H6.10899V8.55753H6.35899V8.30753ZM12.6924 8.30753V8.55753H12.9424V8.30753H12.6924ZM12.6924 1V0.75H12.4424V1H12.6924ZM18.0514 1H18.3014V0.75H18.0514V1ZM18.0514 20V20.25H18.3014V20H18.0514ZM12.6924 20H12.4424V20.25H12.6924V20ZM12.6924 12.6922H12.9424V12.4422H12.6924V12.6922ZM6.35899 12.6922V12.4422H6.10899V12.6922H6.35899ZM6.35899 20V20.25H6.60899V20H6.35899ZM1 20H0.75V20.25H1V20ZM6.35899 0.75H1V1.25H6.35899V0.75ZM6.60899 8.30753V1H6.10899V8.30753H6.60899ZM12.6924 8.05753H6.35899V8.55753H12.6924V8.05753ZM12.9424 8.30753V1H12.4424V8.30753H12.9424ZM12.6924 1.25H18.0514V0.75H12.6924V1.25ZM17.8014 1V20H18.3014V1H17.8014ZM18.0514 19.75H12.6924V20.25H18.0514V19.75ZM12.9424 20V12.6922H12.4424V20H12.9424ZM6.35899 12.9422H12.6924V12.4422H6.35899V12.9422ZM6.60899 20V12.6922H6.10899V20H6.60899ZM1 20.25H6.35899V19.75H1V20.25ZM0.75 1V20H1.25V1H0.75Z" fill="#CC0605" />
    <path d="M21.457 1V0.75H21.207V1H21.457ZM21.457 20H21.207V20.25H21.457V20ZM25.8417 20H26.0917V19.75H25.8417V20ZM25.8417 20.0001H25.5917V20.2501H25.8417V20.0001ZM35.0981 20.0001V20.2501H35.3481V20.0001H35.0981ZM35.0981 15.1283H35.3481V14.8783H35.0981V15.1283ZM26.816 15.1283H26.566V15.3783H26.816V15.1283ZM26.816 12.6922V12.4422H26.566V12.6922H26.816ZM35.0981 12.6922V12.9422H35.3481V12.6922H35.0981ZM35.0981 8.30753H35.3481V8.05753H35.0981V8.30753ZM26.816 8.30753H26.566V8.55753H26.816V8.30753ZM26.816 5.87181V5.62181H26.566V5.87181H26.816ZM35.0981 5.87181V6.12181H35.3481V5.87181H35.0981ZM35.0981 1H35.3481V0.75H35.0981V1ZM21.457 1.25H25.8417V0.75H21.457V1.25ZM21.707 20V1H21.207V20H21.707ZM25.8417 19.75H21.457V20.25H25.8417V19.75ZM26.0917 20.0001V20H25.5917V20.0001H26.0917ZM35.0981 19.7501H25.8417V20.2501H35.0981V19.7501ZM34.8481 15.1283V20.0001H35.3481V15.1283H34.8481ZM26.816 15.3783H35.0981V14.8783H26.816V15.3783ZM26.566 12.6922V15.1283H27.066V12.6922H26.566ZM35.0981 12.4422H26.816V12.9422H35.0981V12.4422ZM34.8481 8.30753V12.6922H35.3481V8.30753H34.8481ZM26.816 8.55753H35.0981V8.05753H26.816V8.55753ZM26.566 5.87181V8.30753H27.066V5.87181H26.566ZM35.0981 5.62181H26.816V6.12181H35.0981V5.62181ZM34.8481 1V5.87181H35.3481V1H34.8481ZM26.816 1.25H35.0981V0.75H26.816V1.25ZM25.8417 1.25H26.816V0.75H25.8417V1.25Z" fill="#CC0605" />
    <path d="M41.702 1H36.2773L42.6883 13.5844V20H47.8664V13.5844L54.2774 1H48.6061L45.1541 9.14288L41.702 1Z" stroke="#CC0605" stroke-width="0.5" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 2H7.35899V9.30756H13.6924V2.00003H19.0514V21.0001H13.6924V13.6922H7.35899V21H2V2Z" fill="#CC0605" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.816 2H22.457V21H27.816V21H36.098V16.1282H27.816V13.6922H36.098V9.30756H27.816V6.87183H36.098V2.00003H27.816V2Z" fill="#CC0605" />
    <path d="M42.9755 2H37.5508L43.9618 14.5844V21H49.1399V14.5844L55.5508 2H49.8796L46.4275 10.1429L42.9755 2Z" fill="#CC0605" />
    <path d="M35.3475 36.5961C35.3475 35.1954 34.2792 34.544 32.3366 34.544H27.9012V38.6156H32.3366C34.3115 38.6482 35.3475 38.0293 35.3475 36.5961ZM27.9012 27.8664V31.7427H31.6891C33.5345 31.7427 34.5382 31.0912 34.5382 29.7883C34.5382 28.4853 33.5345 27.8664 31.6891 27.8664H27.9012ZM39.1678 37.0195C39.1678 39.886 36.9339 41.5147 32.5956 41.5147H24.1133V25H32.1424C36.254 25 38.3584 26.7264 38.3584 29.2997C38.3584 30.9609 37.5167 32.2313 36.1893 32.9153C38.0023 33.5016 39.1678 34.9349 39.1678 37.0195Z" fill="white" />
    <path d="M48.588 28.7459L51.178 35.0651H45.998L48.588 28.7459ZM46.7426 25L39.4258 41.5147H43.3108L44.7677 37.9642H52.3759L53.8328 41.5147H57.815L50.4658 25H46.7426Z" fill="white" />
    <path d="M66.1694 28.127H63.0614V33.8599H66.1694C68.5004 33.8599 69.6659 32.785 69.6659 30.9935C69.6659 29.1694 68.5004 28.127 66.1694 28.127ZM69.7307 41.5147L66.5579 36.9218H66.396H63.0937V41.5147H59.2734V25H66.396C70.7667 25 73.5186 27.2801 73.5186 30.9935C73.5186 33.4691 72.2883 35.2932 70.1516 36.2052L73.8424 41.5147H69.7307Z" fill="white" />
    <path d="M75.332 33.241C75.332 28.4202 78.9905 25 83.9439 25C86.6958 25 88.9621 26.0098 90.4837 27.8339L88.1203 30.0163C87.0519 28.7785 85.7245 28.1596 84.1381 28.1596C81.1596 28.1596 79.0552 30.2443 79.0552 33.2736C79.0552 36.3029 81.1596 38.3876 84.1381 38.3876C85.7245 38.3876 87.0519 37.7362 88.1203 36.4984L90.4837 38.6808C88.9944 40.5375 86.6958 41.5147 83.9439 41.5147C78.9905 41.5147 75.332 38.0619 75.332 33.241Z" fill="white" />
    <path d="M104.016 25V31.5147H96.5692V25H92.7812V41.5147H96.5692V34.7394H104.016V41.5147H107.836V25H104.016Z" fill="white" />
    <path d="M111.43 25V41.5147H124.121V38.4528H115.185V34.6091H122.826V31.645H115.185V28.0619H123.829V25H111.43Z" fill="white" />
    <path d="M151.704 25V28.127H146.459V41.5147H142.671V28.127H134.189V41.5147H130.401V28.127H125.156V25H151.704Z" fill="white" />
    <path d="M157.081 25H153.293V41.5147H157.081V25Z" fill="white" />
    <path d="M6.01382 26.7264L2.77628 37.9967C2.71153 38.2573 2.64678 38.5505 2.58203 38.8436V25H4.55693L4.0713 26.7264H6.01382Z" fill="#DB0812" />
    <path d="M11.8752 34.9349C11.6162 37.671 9.38233 41.4495 6.30667 41.4495C5.01165 41.4495 4.42889 40.7981 4.52602 39.6254C4.55839 38.974 4.6879 38.4854 4.8174 38.0619L5.2059 36.759C6.08004 33.86 8.08731 31.9707 9.86796 31.9707C11.5191 31.9707 12.0047 33.4039 11.8752 34.9349Z" fill="#DB0812" />
    <path d="M18.9646 25V41.5147H10.288L10.3204 41.4821C11.3887 40.6352 12.2629 39.5277 12.878 38.3225C13.4931 37.1173 13.8817 35.8469 13.9788 34.6417C14.173 32.4593 13.396 30.2117 10.7736 30.2117C9.15485 30.2117 7.53608 31.0912 6.33819 32.7199H6.27344L8.50734 25H18.9646Z" fill="#DB0812" />
    <path d="M10.8054 30.2117C9.18661 30.2117 7.56784 31.0912 6.36995 32.7199H6.3052L8.5391 25H4.55693L4.0713 26.7264H6.01382L2.77628 37.9967C2.71153 38.2573 2.64678 38.5505 2.58203 38.8436V41.4495V41.5147H10.3197L10.3521 41.4821C11.4205 40.6352 12.2946 39.5277 12.9098 38.3225C13.5249 37.1173 13.9134 35.8469 14.0105 34.6417C14.2372 32.4593 13.4278 30.2117 10.8054 30.2117ZM11.8738 34.9349C11.6148 37.671 9.38086 41.4495 6.3052 41.4495C5.01019 41.4495 4.42743 40.798 4.52455 39.6254C4.55693 38.9739 4.68643 38.4853 4.81593 38.0619L5.20444 36.759C6.07857 33.8599 8.08585 31.9707 9.86649 31.9707C11.5176 31.9707 12.0033 33.4039 11.8738 34.9349Z" fill="white" />
  </svg>
</template>
