<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
    <mask id="mask0_771_5160" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="4" width="18" height="8">
      <rect y="4" width="18" height="8" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_771_5160)">
      <path d="M9 11.5314L4.5 7.03145L5.55 5.98145L9 9.43145L12.45 5.98145L13.5 7.03145L9 11.5314Z" fill="currentColor" />
    </g>
  </svg>
</template>
